import { render, staticRenderFns } from "./topicdetail.vue?vue&type=template&id=dc232fc4&scoped=true&"
import script from "./topicdetail.vue?vue&type=script&lang=js&"
export * from "./topicdetail.vue?vue&type=script&lang=js&"
import style0 from "./topicdetail.vue?vue&type=style&index=0&id=dc232fc4&scoped=scoped&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc232fc4",
  null
  
)

export default component.exports